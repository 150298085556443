import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import HeroV2 from '../components/HeroV2';
import Image from '../components/Image';
import Button from '../components/Button';
import Action from '../components/Resource/Action';
import HeroImage from '../images/profiles-hero-img-1400x366-2x.jpg';

const textOverlay = (
  <div className="home__text_overlay_container">
    <Container fullWidth={true}>
      <div className="home__text_overlay_inner_container">
        <span className="home__text_overlay_title">Career Profiles</span>
      </div>
    </Container>
  </div>
);

const CareerProfilesPage = ({ data }) => {
  const profiles = data.allCareerProfilesYaml.edges.map((edge) => edge.node);

  return (
    <Layout title="Career Profiles">
      <HeroV2
        mediaSource="profiles-hero-img-1400x366-2x.jpg"
        textOverlay={textOverlay}
      />
      <Section>
        <Container>
          <Row>
            <Column size={8}>
              <h2>New horizons for tomorrow’s STEM superstars</h2>
              <p>
                Students practice cyber skills every day just from interacting
                with their favorite digital technologies. Inspire today’s tech
                savvy students to develop their digital know-how into an
                exciting and innovative career in Cybersecurity.
              </p>
            </Column>
          </Row>
          <Row>
            {profiles.map((profile, i) => {
              if (profile.renderOnMainCareerProfilePage) {
                return (
                  <Column
                    size={4}
                    key={i}
                    className="career-profiles__profile-container"
                  >
                    <div className="career-profiles__profile">
                      <div>
                        <Link to={`/career-profiles/${profile.parent.name}`}>
                          <Image filename={profile.relatedImage} />
                        </Link>
                        <h2 className="career-profiles__profile-headings">
                          {profile.title}
                        </h2>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: profile.shortDescription,
                          }}
                        />
                      </div>
                      <div>
                        {profile.useDropdown ? (
                          <div className="resource__actions">
                            <Action
                              dropdown={true}
                              label="Learn More"
                              actions={profile.actions}
                            />
                          </div>
                        ) : (
                          <Button
                            to={`/career-profiles/${profile.parent.name}`}
                          >
                            Learn More
                          </Button>
                        )}
                      </div>
                    </div>
                  </Column>
                );
              }
            })}
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default CareerProfilesPage;

export const query = graphql`
  query CareerProfilesQuery {
    allCareerProfilesYaml(
      sort: { fields: [sortIndex, title], order: [ASC, ASC] }
    ) {
      edges {
        node {
          title
          sortIndex
          renderOnMainCareerProfilePage
          useDropdown
          shortDescription
          relatedImage
          actions {
            link
            label
            linkClass
            downloadCloseOnClick
          }
          parent {
            ... on File {
              name
            }
          }
        }
      }
    }
  }
`;
