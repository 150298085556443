import React, { useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon';
import Button from '../Button';

import './styles.scss';

const Action = ({
  dropdown,
  label,
  metadata,
  module,
  link,
  linkClass,
  moduleName,
  download,
  downloadCloseOnClick,
  closeDropdownFunction,
  noIcon,
  asLink,
  actions,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const handleDropdownClick = () => setDropdownOpen((state) => !state);

  const renderModuleLink = () => (
    <Link
      to={module}
      className="resource__action-link"
      data-click-type="Module Launch"
      data-click-name={moduleName}
    >
      {!noIcon && <Icon name="module" className="resource__action-icon" />}
      {label}
    </Link>
  );

  const renderLink = () => (
    <Link
      to={link}
      className={`resource__action-link ${linkClass}`}
      onClick={() => {
        if (downloadCloseOnClick) {
          closeDropdownFunction();
        }
      }}
    >
      {label}
    </Link>
  );

  const renderDownload = () => (
    <Button
      to={download}
      target="_blank"
      rel="noopener noreferrer"
      className="resource__action-link"
      link={asLink}
      onClick={() => {
        if (downloadCloseOnClick) {
          closeDropdownFunction();
        }
      }}
    >
      {!noIcon && (
        <Icon name="download" className="resource__action-icon" marginRight />
      )}
      {label}
    </Button>
  );

  const renderDropdown = () => {
    return (
      <>
        <Button
          onClick={handleDropdownClick}
          className={classNames(
            'resource__action-link',
            'resource__dropdown-toggle',
            dropdownOpen && 'resource__dropdown-toggle--open'
          )}
        >
          {label} &nbsp;{' '}
          <Icon
            name="caratdown"
            className={classNames(
              'resource__dropdown-carat',
              dropdownOpen && 'resource__dropdown-carat--open'
            )}
          />
        </Button>
        {dropdownOpen && (
          <div className="resource__dropdown-items">
            {actions.map((action, i) => (
              <Action
                {...action}
                closeDropdownFunction={handleDropdownClick}
                key={i}
              />
            ))}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="resource__action">
      {module && renderModuleLink()}
      {link && renderLink()}
      {download && renderDownload()}
      {metadata && <span className="resource__metadata">{metadata}</span>}
      {dropdown && renderDropdown()}
    </div>
  );
};

Action.propTypes = {
  label: PropTypes.node.isRequired,
  metadata: PropTypes.string,
  module: PropTypes.string,
  download: PropTypes.string,
  noIcon: PropTypes.bool,
  asLink: PropTypes.bool,
  dropdown: PropTypes.bool,
};

export default Action;
